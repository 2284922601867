@use "../common/colors";
@import "../common/variables";

#login-container {
  height: 100%;
}

.login {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  canvas {
    line-height: 0;
    position: fixed;
    top: 0;
    z-index: -1;
  }

  .login-wrapper {
    align-items: center;
    display: flex;
    margin: 0 auto;
    width: 750px;
  }

  .admin-link {
    color: colors.$grey4;
    font-family: $open-sans-family;
    font-size: 14px;
  }

  .error {
    color: colors.$red;
    font-family: $open-sans-family;
    font-size: 12px;
    width: 280px;
  }

  .about-container {
    color: colors.$grey4;
    display: flex;
    flex-direction: column;
    font-family: $open-sans-family;
    justify-content: center;
    margin-left: 10px;
    width: 70%;

    p {
      font-size: 16px;
      margin-bottom: 10px;
    }

    h3 {
      margin-bottom: 10px;
      margin-top: 0;
    }

    @media (width <= 480px) {
      display: none;
    }
  }

  .login-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    .login-image {
      background-image: url("../images/characters.svg");
      background-repeat: no-repeat;
      background-size: 800%;
      display: block;
      height: 300px;
      margin: -30px auto 0;
      width: 300px;
    }

    @for $i from 0 through 6 {
      $c: $i * -300px;

      .login-image.person-#{$i} {
        background-position: $c 0;
      }
    }

    .login-title {
      color: colors.$grey4;
      display: block;
      font-size: 1.5em;
      font-weight: 300;
      margin: -20px 0 5px;
    }

    .login-subtitle {
      color: colors.$grey4;
      font-family: $muli-family;
      font-size: 1.2em;
      font-weight: 100;
      margin-bottom: 30px;
      opacity: 0.6;
    }
  }
}
