/* Spacing */
$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-s: 15px;

/* Icons */
$sta-icon-add: "\e000";
$sta-icon-sheet: "\e001";
$sta-icon-edit: "\e002";
$sta-icon-folder: "\e003";
$sta-icon-google: "\e004";
$sta-icon-notification: "\e005";
$sta-icon-shared-folder: "\e006";
$sta-icon-chat: "\e007";

/* Fonts */
$open-sans-family: "Open Sans", sans-serif;
$muli-family: "Muli", sans-serif;
// stylelint-disable-next-line value-keyword-case
$flaticon-family: Flaticon;
